import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Link from 'gatsby-link';
import Button from '../Button';
import styles from './index.module.less';
import logo from './logo.png';

const SiteHeader = () => (
  <header className={styles.root}>
    <Grid fluid>
      <Row middle="xs">
        <Col xs={2}>
          <Link to="/">
            <img className={styles.logo} src={logo} alt="Ryhaven Logo" />
          </Link>
        </Col>
        <Col xs={10}>
          <nav className={styles.nav}>
            <ul className={styles.navList}>
              <li className={styles.navListItem}>
                <Link className={styles.link} to="/">
                  Home
                </Link>
              </li>
              <li className={styles.navListItem}>
                <Link className={styles.link} to="/about-us">
                  About us
                </Link>
              </li>
              <li className={styles.navListItem}>
                <Link className={styles.link} to="/recent-projects">
                  Recent projects
                </Link>
              </li>
              <li className={styles.navListItem}>
                <Link className={styles.link} to="/contact-us">
                  Contact us
                </Link>
              </li>
              <li className={styles.navListItem}>
                <Button to="/apply" type="primary">
                  Apply
                </Button>
              </li>
            </ul>
          </nav>
        </Col>
      </Row>
    </Grid>
  </header>
);

export default SiteHeader;
