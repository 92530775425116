import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Link from 'gatsby-link';
import styles from './index.module.less';
import facebook from 'images/facebook.png';

const SiteFooter = () => (
  <footer className={styles.root}>
    <Grid fluid>
      <Row>
        <Col xs={6}>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>Contact Us</h2>
            <a className={styles.link} href="mailto:info@ryhaven.org.nz">
              info@ryhaven.org.nz
            </a>
            <br />
            <a className={styles.link} href="tel:+642108578617">
              021 085 78617
            </a>
          </section>
        </Col>
        <Col xs={6} className={styles.facebook}>
          <a className={styles.link} href="https://www.facebook.com/ryhaventrust/">
            <img src={facebook} alt="Find us on Facebook" />
          </a>
        </Col>
      </Row>
      <hr className={styles.separator} />
      <Row>
        <Col md={8}>
          <nav className={styles.nav}>
            <ul className={styles.navList}>
              <li className={styles.navListItem}>
                <Link className={styles.link} to="/">
                  Home
                </Link>
              </li>
              <li className={styles.navListItem}>
                <Link className={styles.link} to="/about-us">
                  About us
                </Link>
              </li>
              <li className={styles.navListItem}>
                <Link className={styles.link} to="/recent-projects">
                  Recent projects
                </Link>
              </li>
              {/* <li className={styles.navListItem}><Link className={styles.link} to="/newsletter">Newsletter</Link></li> */}
              <li className={styles.navListItem}>
                <Link className={styles.link} to="/contact-us">
                  Contact us
                </Link>
              </li>
              <li className={styles.navListItem}>
                <Link className={styles.link} to="/apply">
                  Apply
                </Link>
              </li>
            </ul>
          </nav>
        </Col>
        <Col md={4}>
          <small className={styles.small}>
            <p>&copy; 2018 Ryhaven Charitable Trust</p>
            <p>Charity Registration Number: CC55138</p>
          </small>
        </Col>
      </Row>
    </Grid>
  </footer>
);

export default SiteFooter;
