import React from 'react';
import styles from './index.module.less';
import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';

const Layout = props => (
  <div className={styles.root}>
    <SiteHeader />
    <main className={styles.main}>{props.children}</main>
    <SiteFooter />
  </div>
);

export default Layout;
