import React from 'react';
import styles from './index.module.less';

const Title1 = props => <h1 className={styles.title1}>{props.children}</h1>;

const Title2 = props => <h2 className={styles.title2}>{props.children}</h2>;

const Title3 = props => <h3 className={styles.title3}>{props.children}</h3>;

const Paragraph = props => <p className={styles.paragraph}>{props.children}</p>;

const List = props => <ul className={styles.list}>{props.children}</ul>;

const ListItem = props => <li className={styles.listItem}>{props.children}</li>;

const Blockquote = props => <blockquote className={styles.blockquote}>{props.children}</blockquote>;

export { Title1, Title2, Title3, Paragraph, List, ListItem, Blockquote };
