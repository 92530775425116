import React from 'react';
import Link from 'gatsby-link';
import styles from './index.module.less';

const Button = props => (
  <Link className={styles.button} to={props.to}>
    {props.children}
  </Link>
);

export default Button;
