import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PageHeader from 'components/PageHeader';
import styles from './index.module.less';
import { Title2, Paragraph } from 'components/Typography';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <PageHeader title="Thank you" intro="Your application has been sent!" />
    <section>
      <Grid fluid>
        <Row>
          <Col md={8} className={styles.main}>
            <Title2>What happen's next?</Title2>
            <Paragraph>We will be in touch as soon to talk about your application.</Paragraph>
            <Paragraph>
              If your application is successful, the trust will determine the appropriate assistance
              required. Needs are assessed on a case-by-case basis.
            </Paragraph>
          </Col>
        </Row>
      </Grid>
    </section>
  </Layout>
);

export default Page;
